import React, { useEffect, useContext, useRef } from "react";
import ResourceCard from "./resource-card";
import tw, { css } from "twin.macro";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronRight,
	faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from "framer-motion";
import { ThemeContext } from "../themes/theme-context";
import useResourceFilter from "./resource-filter-hook";
import { useIntl } from "gatsby-plugin-intl";

const StyledResourceCarousel = tw.div`w-full h-full`;

const sliderCss = css`
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		// -webkit-user-select: none;
		// -moz-user-select: none;
		// -ms-user-select: none;
		// user-select: none;
		// -webkit-touch-callout: none;
		// -khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
	}

	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.slick-track:before,
	.slick-track:after {
		display: table;
		content: "";
	}

	.slick-track:after {
		clear: both;
	}

	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;
		height: 100%;
		min-height: 1px;
	}

	[dir="rtl"] .slick-slide {
		float: right;
	}

	.slick-slide img {
		display: block;
	}

	.slick-slide.slick-loading img {
		display: none;
	}

	.slick-slide.dragging img {
		pointer-events: none;
	}

	.slick-initialized .slick-slide {
		display: block;
	}

	.slick-loading .slick-slide {
		visibility: hidden;
	}

	.slick-vertical .slick-slide {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}

	.slick-arrow.slick-hidden {
		display: none;
	}

	.slick-loading .slick-list {
		background: #fff url("./ajax-loader.gif") center center no-repeat;
	}
`;

const StyledArrow = tw.button`absolute top-1/2 w-12 h-12 rounded-full bg-white flex flex-col items-center justify-center shadow-resource-list-card z-10 lg:w-16 lg:h-16 transition duration-150 ease-linear transform hover:scale-105 cursor-pointer lg:top-44 text-white`;

const StyledPrevArrow = tw(
	StyledArrow
)`-left-2.5 md:left-44 lg:left-8 xl:left-44`;

const StyledNextArrow = tw(
	StyledArrow
)`-right-2.5 md:right-44 lg:right-8 xl:right-44`;

const ResourceCarousel = ({
	data,
	share,
	featuredCategory,
	resourceFeedbackData,
}) => {
	const resourceData = data.nodes;

	const controls = useAnimation();

	const carouselAnimation = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0 },
	};

	const slider = useRef(null);
	const resources = useResourceFilter(featuredCategory, resourceData);

	useEffect(() => {
		slider.current.slickGoTo(0, true);

		controls
			.start({ opacity: 0, y: 50, transition: { duration: 0 } })
			.then(() => controls.start("visible"));
	}, [controls, slider, resources]);

	const { theme } = useContext(ThemeContext);
	const intl = useIntl();

	const PreviousArrow = (props) => {
		const { onClick } = props;
		return (
			<StyledPrevArrow
				onClick={onClick}
				aria-label={intl.formatMessage({ id: "prev_button" })}
				css={theme.bgColor}
			>
				<FontAwesomeIcon icon={faChevronLeft} aria-hidden="true" />
			</StyledPrevArrow>
		);
	};

	const NextArrow = (props) => {
		const { onClick } = props;
		return (
			<StyledNextArrow
				onClick={onClick}
				aria-label={intl.formatMessage({ id: "next_button" })}
				css={theme.bgColor}
			>
				<FontAwesomeIcon icon={faChevronRight} aria-hidden="true" />
			</StyledNextArrow>
		);
	};

	const sliderSettings = {
		infinite: true,
		speed: 400,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PreviousArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					speed: 200,
				},
			},
		],
	};

	return (
		<StyledResourceCarousel css={sliderCss}>
			<motion.div
				initial="hidden"
				animate={controls}
				variants={carouselAnimation}
				transition={{ duration: 0.8 }}
			>
				<Slider {...sliderSettings} ref={slider}>
					{resources.map((item) => {
						// console.log(item.title);
						return (
							<ResourceCard
								key={item.title}
								data={item}
								featuredCategory={featuredCategory}
								resourceFeedbackData={resourceFeedbackData}
								shareData={share}
							/>
						);
					})}
				</Slider>
			</motion.div>
		</StyledResourceCarousel>
	);
};

export default ResourceCarousel;
