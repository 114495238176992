import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { GatsbyImage } from "gatsby-plugin-image";
import { ThemeContext } from "../themes/theme-context";

const StyleImageContainer = tw.div`flex items-center justify-center w-full md:p-4 mb-4 relative lg:mb-0 lg:p-6 lg:pl-12 xl:pl-16`;

const ImageBackingLeft = styled.div(({ backingGradient }) => [
	tw`absolute w-12 h-12 lg:w-28 lg:h-28 left-7 top-0 rounded-full filter blur-sm lg:left-4 xl:left-14`,
	backingGradient,
]);

const ImageBackingRight = tw.div`absolute bottom-0 right-0 w-28 lg:w-56 lg:-bottom-2`;

const StyledImage = tw.div`w-32 h-32 lg:w-60 lg:h-60`;

const Overlay = tw.div`absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-gradient-thumbnail-overlay1 to-gradient-thumbnail-overlay2`;

const ResourceThumbnail = ({ thumbnail, title }) => {
	const { theme } = useContext(ThemeContext);

	return (
		<StyleImageContainer>
			<ImageBackingLeft backingGradient={theme.thumbnailBackingLeft} />
			<ImageBackingRight>
				<img
					tw="w-full h-full"
					src={theme.thumbnailBackingRight}
					aria-hidden="true"
					alt={title}
				/>
			</ImageBackingRight>
			<StyledImage>
				<GatsbyImage
					image={thumbnail.image}
					alt={title}
					tw="w-full h-full rounded-3xl shadow-resource-thumbnail"
				/>
			</StyledImage>
			<Overlay />
		</StyleImageContainer>
	);
};

export default ResourceThumbnail;
